import { createRouter, createWebHistory, NavigationGuardNext, RouteRecordRaw } from 'vue-router'
import store from '@/store/index'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/move/order/boxes',
		name: 'MovingOrderBoxesPage',
		component: () => import('@/pages/move/order/boxes/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [7, 8, 9])
		}
	},

	{
		path: '/orders',
		name: 'AllOrdersPage',
		component: () => import('@/pages/orders/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [2, 7, 8, 9, 10])
		}
	},

	{
		path: '/photomodels',
		name: 'AllPhotomodelsPage',
		component: () => import('@/pages/photomodels/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [9])
		}
	},

	{
		path: '/currencies',
		name: 'CurrenciesPage',
		component: () => import('@/pages/currencies/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [9])
		}
	},

	{
		path: '/home/edit',
		name: 'HomeEditPage',
		component: () => import('@/pages/home-edit/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [9])
		}
	},

	{
		path: '/orders/collecting',
		name: 'CollectingOrdersPage',
		component: () => import('@/pages/orders/collecting/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [2, 7, 8, 9, 10])
		}
	},

	{
		path: '/orders/sending',
		name: 'SendingOrdersPage',
		component: () => import('@/pages/orders/sending/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [2, 7, 8, 9, 10])
		}
	},

	{
		path: '/orders/received',
		name: 'ReceivedOrdersPage',
		component: () => import('@/pages/orders/received/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [2, 7, 8, 9, 10])
		}
	},

	{
		path: '/orders/shipped',
		name: 'ShippedOrdersPage',
		component: () => import('@/pages/orders/shipped/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [2, 7, 8, 9, 10])
		}
	},

	{
		path: '/order/:id',
		name: 'FullOrderPage',
		component: () => import('@/pages/order/_id.vue'),
		beforeEnter(to, from, next) {
			if (!Number.isInteger(Number(to.params.id))) return { name: 'Error' }
			return checkRole(next, [2, 7, 8, 9, 10])
		}
	},

	{
		path: '/move/product/transfer-storage',
		name: 'ToTransferProductPage',
		component: () => import('@/pages/move/product/transfer-storage/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [6, 9])
		}
	},

	{
		path: '/search/variation',
		name: 'SearchProductByVariationPage',
		component: () => import('@/pages/search/variation/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [4, 5, 9, 10])
		}
	},

	{
		path: '/move/product/kingmode',
		name: 'KingmodePage',
		component: () => import('@/pages/move/product/kingmode/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [9])
		}
	},

	{
		path: '/size/add',
		name: 'AddSizeValuePage',
		component: () => import('@/pages/size/add/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [9])
		}
	},

	{
		path: '/size/manager',
		name: 'AddSizeValueManagerPage',
		component: () => import('@/pages/size/manager.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [9])
		}
	},
	{
		path: '/reports/',
		name: 'ReportPage',
		component: () => import('@/pages/report/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [9])
		}
	},

	{
		path: '/',
		name: 'MainPage',
		component: () => import('@/pages/index.vue')
	},

	{
		path: '/warehouse-map',
		name: 'WarehouseMap',
		component: () => import('@/pages/warehouse_map/index.vue')
	},

	{
		path: '/products',
		name: 'ProductPage',
		component: () => import('@/pages/products/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [4, 5, 6, 9, 10])
		}
	},

	{
		path: '/products-to-sale',
		name: 'SalesProductPage',
		component: () => import('@/pages/products/sales.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [4, 5, 6, 9, 10])
		}
	},

	{
		path: '/product/:id',
		name: 'ProductUpdate',
		component: () => import('@/pages/product/_update.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [4, 5, 6, 9, 10])
		}
	},

	{
		path: '/product/add',
		name: 'ProductAdd',
		component: () => import('@/pages/product/add.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [4, 9])
		}
	},

	{
		path: '/lots',
		name: 'LotsPage',
		component: () => import('@/pages/lots/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [3, 9, 10])
		}
	},

	{
		path: '/lot/:id',
		name: 'LotUpdate',
		component: () => import('@/pages/lot/_update.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [3, 9, 10])
		}
	},

	{
		path: '/lot/add',
		name: 'LotAdd',
		component: () => import('@/pages/lot/add.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [3, 9])
		}
	},

	{
		path: '/search/order',
		name: 'SearchOrderByIdPage',
		component: () => import('@/pages/search/order/index.vue'),
		beforeEnter(to, from, next) {
			return checkRole(next, [2, 7, 8, 9, 10])
		}
	},

	{
		path: '/search/product',
		name: 'SearchProduct',
		component: () => import('@/pages/search/variation/index.vue')
	},

	{
		path: '/login',
		name: 'LoginPage',
		component: () => import('@/pages/login/index.vue'),
		meta: {
			layout: 'LoginLayout'
		}
	},

	{
		path: '/:pathMatch(.*)*',
		name: 'Error',
		component: () => import('@/components/404/index.vue'),
		meta: {
			layout: 'LoginLayout'
		}
	},

	{
		path: '/:pathMatch(.*)*',
		name: 'AuthError',
		component: () => import('@/components/401/index.vue'),
		meta: {
			layout: 'LoginLayout'
		}
	}
]

const router = createRouter({
	history: createWebHistory('/'),
	routes
})

router.beforeEach(to => {
	if (!store.state.auth.isAuth && to.name !== 'LoginPage' && to.matched[0].path !== '/:pathMatch(.*)*') {
		if (to.name === 'OrderByIdPage') store.commit('setOrderId', to.params.id)

		return {
			name: 'LoginPage'
		}
	}

	if (store.state.auth.isAuth && to.name === 'LoginPage') {
		return {
			name: 'DashboardPage'
		}
	}
})

function checkRole(next: NavigationGuardNext, roles: Array<number>) {
	const userRoleIds = store.state.auth?.userRoleIds
	for (const userRoleId of userRoleIds) {
		if (roles.includes(userRoleId)) return next()
	}
	return next({ name: 'AuthError' })
}

export default router
